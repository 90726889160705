import React from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    // Get the current year
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate('/account');
  };
  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-6 md:px-12 lg:px-24 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Transform Your School with Our Educational Management Software
          </h1>
          <p className="text-xl mb-6">
            Manage student information, track academic progress, and streamline school operations efficiently.
          </p>
          <button 
            onClick={handleGetStarted}
            className="bg-white text-blue-600 font-semibold py-3 px-8 rounded-full hover:bg-blue-500 hover:text-white">
                Get Started
          </button>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16">
        <div className="container mx-auto px-6 md:px-12 lg:px-24 text-center">
          <h2 className="text-3xl font-bold mb-10">Why Choose Us?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Student Management</h3>
              <p className="text-gray-600">
                Keep track of student data, grades, fees and attendance in one place.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Teacher Tools</h3>
              <p className="text-gray-600">
                Equip teachers with tools to manage classes, assessments, and communication.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Parent Portal</h3>
              <p className="text-gray-600">
                Allow parents to stay updated on their child's progress through a secure portal.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto text-center">
          {/* <p className="text-sm">&copy; {currentYear} EduHub. JOHMAK TECHNOLOGIES LTD. All rights reserved.</p> */}
          <p className="text-sm">
                &copy; {currentYear} EduHub.{' '}
                <a 
                    href="https://johmak.com/" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-white hover:underline"
                >
                    JOHMAK TECHNOLOGIES LTD
                </a>. All rights reserved.
            </p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
