import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Account = () => {
  const [uniqueID, setUniqueID] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
  const [error, ] = useState('');

  const handleProceed = async () => {
    // If the unique ID is 'demo', open the demo link in a new tab
    if (uniqueID === 'demo') {
      window.open('https://eduhub.johmak.com/demo/', '_blank'); // Open in a new tab
    } else {
      // Make an API request to check if the ID exists
      try {
        const response = await fetch(`https://your-api-endpoint.com/check-id/${uniqueID}`);
        const data = await response.json();
        
        // If the ID exists, redirect to the associated link
        if (response.ok && data.exists) {
          window.open(data.redirectUrl, '_blank'); // Open the URL in a new tab
        } else {
          alert('The account does not exist');
        }
      } catch (error) {
        console.error('Error checking ID:', error);
        alert('An error occurred while checking the ID');
      }
    }
  };
  // Function to handle username input changes
    const handleUniqueIDChange = (e) => {
        const input = e.target.value.replace(/\s/g, ''); // Remove any spaces
        // Only allow alphanumeric characters (letters and digits), but no special characters
        if (/^[a-zA-Z0-9]*$/.test(input)) { 
        setUniqueID(input.toLowerCase()); // Convert to lowercase and set the state
        }
    };
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-teal-400 px-4 py-12 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">EduHub Account Login</h2>
          <p className="mt-2 text-sm text-gray-600">Please enter your unique ID  to access your account.</p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleProceed}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="uniqueID" className="sr-only">Unique ID</label>
              <input 
                id="uniqueID" 
                name="uniqueID" 
                type="text" 
                value={uniqueID} 
                onChange={handleUniqueIDChange} // Call the function here
                required 
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                placeholder="Enter Unique ID" 
              />
            </div>
          </div>
          {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
          <div>
            <button 
              type="submit" 
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Proceed
            </button>
          </div>
        </form>
        <div className="mt-6 flex justify-between text-sm">
          <button 
            className="text-indigo-600 hover:text-indigo-500"
            type="button"
            onClick={() => console.log('Forgot ID clicked')}
          >
            Forgot ID?
          </button>
          <Link 
            to="/" 
            className="text-indigo-600 hover:text-indigo-500 text-center"
          >
            Back
          </Link>
          <button 
            className="text-indigo-600 hover:text-indigo-500"
            type="button"
            onClick={() => console.log('New user clicked')}
          >
            Don't have an account?
          </button>
        </div>
      </div>
    </div>
  );
};

export default Account;